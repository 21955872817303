import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4EventName, GA4FilterListType, trackFilterInteraction } from '@jsmdg/tracking';
import { Accordion, Badge } from '@jsmdg/yoshi';
import { type AccordionItemId } from '@jsmdg/yoshi/dist/components/Accordion/AccordionContext';
import { StaticFilter } from '../../../../shared/enums/staticFilter';
import { type FacetFilter } from '../../../../shared/types/facetFilter';
import { type FragmentContext } from '../../../../shared/types/fragmentContext';
import { type Filter, type Sorting as SortingType } from '../../../../shared/types/search';
import { getGridView } from '../../../helper/getGridView';
import { type SearchReducerActionType } from '../../../reducers/searchReducer';
import { type SearchReducerValue } from '../../../types/searchReducer';
import { DistanceFilter } from '../Distance';
import { LocationFilter } from '../Location';
import { PriceFilter } from '../Price';
import { ProductFacet } from '../ProductFacets/ProductFacet';
import { Sorting } from '../Sorting';
import { AttributeAccordionTitle } from './AttributeAccordionTitle';
import styles from '../Filter.module.scss';

type FilterSidePanelBodyProps = {
    readonly filter: Filter;
    readonly sorting?: SortingType;
    readonly showLocationFilter: boolean;
    readonly shouldReset: boolean;
    readonly isInitialLocation: boolean;
    readonly isInitialPrice: boolean;
    readonly onFilterChange: (
        type: SearchReducerActionType,
        value?: SearchReducerValue,
        name?: string,
    ) => void;
    readonly onSortingChange: (type: SearchReducerActionType, value?: SearchReducerValue) => void;
    readonly geoLocationError?: GeolocationPositionError;
    readonly facets?: FacetFilter[];
    readonly paginationLimit?: number;
    readonly isModalView?: boolean;
    readonly className?: string;
};

const FilterSidePanelBody = ({
    className,
    facets,
    filter,
    geoLocationError,
    isInitialLocation,
    isInitialPrice,
    isModalView,
    onFilterChange,
    onSortingChange,
    paginationLimit,
    shouldReset,
    showLocationFilter,
    sorting,
}: FilterSidePanelBodyProps): JSX.Element => {
    const { settingCookie, tenantConfig } = useFragmentContext<FragmentContext>();

    const openCollapsible = useRef('');
    const [isPriceFilterOpen, setIsPriceFilterOpen] = useState(false);
    const gridView = getGridView(settingCookie);
    const listType = isModalView ? GA4FilterListType.MapViewMenu : GA4FilterListType[gridView];

    const onPriceFilterToggle = async (): Promise<void> => {
        setIsPriceFilterOpen(!isPriceFilterOpen);

        if (!isPriceFilterOpen) {
            openCollapsible.current = '';
            await window.yieldToMainThread();
            trackFilterInteraction(`Expand_${StaticFilter.Price}`, undefined, {
                eventName: GA4EventName.ExpandFilter,
                filter_type: 'Price',
                list_type: listType,
            });
        }
    };

    const onToggle = async (itemId: AccordionItemId, isOpen: boolean): Promise<void> => {
        const isFacetOpen = isOpen && itemId;
        openCollapsible.current = isFacetOpen ? (itemId as string) : '';

        if (openCollapsible.current) {
            setIsPriceFilterOpen(false);
        }

        if (isFacetOpen && itemId !== StaticFilter.Price) {
            await window.yieldToMainThread();
            trackFilterInteraction(`Expand_${itemId as string}`, undefined, {
                eventName: GA4EventName.ExpandFilter,
                filter_type: itemId as string,
                list_type: listType,
            });
        }

        if (itemId === StaticFilter.Price) await onPriceFilterToggle();
    };

    return (
        <div className={classNames(styles.filterContent, className)}>
            {showLocationFilter && (
                <>
                    <LocationFilter
                        locationName={filter.location?.name}
                        onSubmit={onFilterChange}
                        shouldReset={shouldReset && !isInitialLocation}
                        geoLocationError={geoLocationError}
                        isModalView={isModalView}
                        className={isModalView ? 'px-2-5x' : ''}
                        listType={listType}
                    />
                    {!isModalView && (
                        <DistanceFilter
                            show={!!filter.location?.name}
                            distanceValue={filter.location?.distance}
                            shouldReset={shouldReset && !isInitialLocation}
                            onSubmit={onFilterChange}
                            filter={filter}
                            listType={listType}
                        />
                    )}
                </>
            )}

            <div className={!isModalView ? 'pt-3x pb-12x' : ''}>
                <Accordion autoClose onSelect={onToggle}>
                    <Accordion.Item
                        key={StaticFilter.Price}
                        id={StaticFilter.Price}
                        title={
                            <div
                                className={classNames('d-flex fw-semibold', {
                                    [styles.activeFilter]: !isInitialPrice,
                                })}
                            >
                                <FormattedMessage defaultMessage="Preis" />
                                {!isInitialPrice && <Badge className="ml-1x">1</Badge>}
                            </div>
                        }
                        className={classNames(styles.collapsible, 'px-2x')}
                    >
                        <PriceFilter
                            values={[filter.price?.min || null, filter.price?.max || null]}
                            onSubmit={onFilterChange}
                            shouldReset={shouldReset && !isInitialPrice}
                            currencyCode={tenantConfig.currency.code}
                            isModalView={isModalView}
                            filter={filter}
                            listType={listType}
                        />
                    </Accordion.Item>
                    {facets?.map(facet => {
                        const selectedOptions = filter.productAttributes?.[facet.attribute];

                        return (
                            <Accordion.Item
                                key={facet.name}
                                id={facet.name}
                                title={<AttributeAccordionTitle filter={filter} facet={facet} />}
                                className={classNames(styles.collapsible, 'px-2x')}
                            >
                                <ProductFacet
                                    facet={facet}
                                    selectedOptions={selectedOptions}
                                    onFilterChange={onFilterChange}
                                    key={facet.name}
                                    paginationLimit={paginationLimit}
                                    isMobileFilter
                                    filter={filter}
                                    listType={listType}
                                />
                            </Accordion.Item>
                        );
                    })}
                    <Accordion.Item
                        key={StaticFilter.Sorting}
                        id={StaticFilter.Sorting}
                        title={
                            <div className={classNames('fw-semibold', styles.activeFilter)}>
                                <FormattedMessage defaultMessage="Sortieren nach" />
                            </div>
                        }
                        className={classNames(styles.collapsible, 'px-2x')}
                        contentClasses="px-0"
                    >
                        <Sorting
                            selectedSorting={sorting}
                            onSubmit={onSortingChange}
                            includeDistanceSorting={showLocationFilter && !!filter.location?.name}
                            targetGroup={filter.targetGroup?.name}
                            listType={listType}
                        />
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    );
};

export { FilterSidePanelBody };
